import { PaginationState } from "@tanstack/react-table";
import { AxiosResponse } from "axios";

import {
  TableFilteredParams,
  TableSortingParams,
} from "../../../../main/components/DataTableServer/useTableServer";
import { recordApi } from "../../../../main/services/RecordService";
import { IPaginateResponse } from "../../../../types/main/pagination";
import {
  IResponse,
  ISuccessfulResponseMessage,
} from "../../../../types/main/response";
import {
  CARList,
  ITariffCar,
  ITariffCarData,
  ITariffInterestAndFineRequest,
} from "../../../../types/main/tariffCar";
import { ICardCarProps } from "../../Tariff/components/TariffDetails/Car/CardCar";

export interface ICarUpdateData
  extends Omit<
    ICardCarProps,
    | "isReadOnly"
    | "status"
    | "uuid"
    | "cancell"
    | "service"
    | "reloadCar"
    | "refreshTable"
  > {
  value: number;
  discount: number;
}

type UpdateCarProps = {
  uuid?: string;
  data: ICarUpdateData;
};

interface IInterestAndFine {
  dueDate: Date;
  idContract: number;
  invoiceValue: number;
  invoiceFine: number;
  invoiceInterest: number;
  invoiceTotal: number;
}

const MODULE_BASE_URL = "/tariffcar";

export const calculateContractInterestAndFine = async ({
  dueDate,
  idContract,
  invoiceValue,
  invoiceDiscount,
  discardInterestAndFine,
}: ITariffInterestAndFineRequest) => {
  try {
    const response: AxiosResponse<IResponse<IInterestAndFine>> =
      await recordApi.post(`${MODULE_BASE_URL}/calculate/interestfine`, {
        dueDate,
        idContract,
        invoiceValue,
        invoiceDiscount,
        discardInterestAndFine,
      });

    return response.data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};

export const updateCar = async ({ data, uuid }: UpdateCarProps) => {
  try {
    const response: AxiosResponse<IResponse<Array<any>>> =
      await recordApi.patch(`${MODULE_BASE_URL}/${uuid}`, data);

    return response.data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};

type TariffsListParams = {
  pagination?: PaginationState;
  filters?: TableFilteredParams;
  sorting?: TableSortingParams;
};

export const getAllCARs = async ({
  filters,
  pagination,
  sorting,
}: TariffsListParams): Promise<IResponse<IPaginateResponse<CARList>>> => {
  try {
    const page = pagination?.pageIndex;

    const { data } = await recordApi.get<IResponse<IPaginateResponse<CARList>>>(
      MODULE_BASE_URL,
      {
        params: {
          page: page === undefined ? 1 : page + 1,
          take: pagination?.pageSize ?? 10,
          ...filters,
          ...sorting,
        },
      }
    );
    return data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};

export const getCarsAvailableToGeneratePix = async (input: {
  document?: string;
  invoiceId?: number | string;
  paymentChargeUuid?: string;
}) => {
  try {
    const { data } = await recordApi.get(
      `${MODULE_BASE_URL}/available/for-pix`,
      {
        params: {
          document: input.document,
          invoiceId: input.invoiceId,
          paymentChargeUuid: input.paymentChargeUuid,
        },
      }
    );
    return data.body.data;
  } catch (err: any) {
    throw err?.response?.data || err;
  }
};

export const generatePix = async (input: {
  document: string;
  receivables: {
    id: number;
  }[];
  discardInterestAndFine: boolean;
}) => {
  try {
    const { data } = await recordApi.post(
      `${MODULE_BASE_URL}/generate-pix`,
      input
    );
    return data.body;
  } catch (error: Error | any) {
    throw error?.response?.data || error;
  }
};

export const getCarCsvByUUIDs = async (filters: TableFilteredParams) => {
  try {
    const { data } = await recordApi.post(`getCarCsvByUuids`, undefined, {
      params: {
        ...filters,
      },
    });
    return data;
  } catch (err: any) {
    return null;
  }
};

export const getCarCsvAllUUIDs = async () => {
  try {
    const { data } = await recordApi.get(`getCarCsvAllUuids`);
    return data;
  } catch (err: any) {
    return null;
  }
};

export const createTariffCar = async ({
  data,
  id,
}: {
  data: Array<ITariffCarData>;
  id: number;
}) => {
  try {
    const response: AxiosResponse<IResponse<ISuccessfulResponseMessage>> =
      await recordApi.post(`${MODULE_BASE_URL}/create/${id}`, [...data]);
    return response.data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};

export const getTariffCarById = async (
  uuid: string
): Promise<IResponse<ITariffCar>> => {
  try {
    const { data }: AxiosResponse<IResponse<ITariffCar>> = await recordApi.get(
      `${MODULE_BASE_URL}/${uuid}`
    );
    return data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};

export const countReceivableMissingPaymentMethod = async () => {
  try {
    const { data }: AxiosResponse<IResponse<number>> = await recordApi.get(
      `${MODULE_BASE_URL}/process/payment-methods/count`
    );
    return data.body ?? 0;
  } catch (err: any) {
    return 0;
  }
};

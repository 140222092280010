import { CopyIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { CellContext } from "@tanstack/react-table";
import React, { useCallback, useState } from "react";
import { IconEdit } from "../../../../../main/common/assets";
import { ConfirmationPopover } from "../../../../../main/components/ConfirmationPopover";
import { HSmallmd } from "../../../../../main/components/Tipography";
import { ToastError } from "../../../../../main/components/ToastError/ToastError";
import { PixGenerationForm } from "../../../CARs/components/PixGenerationForm";
import { paymentsChargesService } from "../../services/PaymentsChargesService";

type PopoverCARProps = {
  info?: CellContext<any, any>;
  onCancelPixSuccess?: () => void;
};

export const PopoverPaymentCharges: React.FC<PopoverCARProps> = ({
  info,
  onCancelPixSuccess,
}) => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);

  const toast = useToast();

  const {
    isOpen: isOpenVisualizeReceivablesModal,
    onOpen: onOpenVisualizeReceivablesModal,
    onClose: onCloseVisualizeReceivablesModal,
  } = useDisclosure();

  const expirePix = async () => {
    setIsLoading(true);
    toast({
      status: "info",
      title: "Cancelando Pix...",
    });
    try {
      await paymentsChargesService.expire({
        paymentChargeUuid: info?.row.original.paymentChargeUuid,
      });
      toast({
        title: "Pix cancelado com sucesso",
        status: "success",
      });
      onCancelPixSuccess?.();
    } catch (error: Error | any) {
      const errorMessage = error?.response?.data?.message;
      toast({
        title: "Houve um erro ao cancelar o Pix",
        status: "error",
        description: <ToastError message={errorMessage} />,
      });
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  const receivable = info?.row.original;
  const status = receivable?.status;

  const copyTransactionCodeToClipboard = useCallback(() => {
    try {
      navigator.clipboard.writeText(receivable?.transactionCode ?? "");
      toast({
        title: "Código copiado para a área de transferência",
        status: "success",
      });
      onClose();
    } catch (error) {
      toast({
        title: "Houve um erro ao copiar o código",
        status: "error",
      });
    }
  }, []);

  return (
    <Popover placement="left" isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      {() => (
        <>
          <PopoverTrigger>
            <Button
              colorScheme="gray"
              variant="outline"
              border="0"
              color="gray.700"
              _hover={{ bg: "gray.200" }}
            >
              ...
            </Button>
          </PopoverTrigger>
          <Portal>
            <PopoverContent width="242px">
              <PopoverBody px="0" mt="15px">
                <Flex flexDir="column">
                  {!!receivable && (
                    <>
                      <Box pl="24px">
                        <Flex
                          py="8px"
                          as="button"
                          color="gray.600"
                          alignItems="center"
                          transition="all 0.2s ease-in-out"
                          onClick={() => onOpenVisualizeReceivablesModal()}
                        >
                          <IconEdit />
                          <HSmallmd ml="8.46px">Visualizar CARs</HSmallmd>
                        </Flex>
                      </Box>

                      <Box pl="24px">
                        <Flex
                          py="8px"
                          as="button"
                          alignItems="center"
                          color="gray.600"
                          transition="all 0.2s ease-in-out"
                          onClick={() => copyTransactionCodeToClipboard()}
                        >
                          <CopyIcon />
                          <HSmallmd ml="8.46px">Copiar PIX</HSmallmd>
                        </Flex>
                      </Box>

                      <Box pl="24px">
                        <Flex
                          py="8px"
                          as="button"
                          alignItems="center"
                          color={status === "PENDING" ? "red.600" : "gray.200"}
                          disabled={status !== "PENDING"}
                          transition="all 0.2s ease-in-out"
                          title={
                            status === "PENDING"
                              ? "Expirar pagamento"
                              : "Não é possível cancelar um pagamento que não está pendente"
                          }
                        >
                          <ConfirmationPopover
                            disabled={status !== "PENDING" || isLoading}
                            triggerButtonText="Expirar Pagamento"
                            onPrimaryButtonClick={expirePix}
                            title="Confirmação"
                            text="Tem certeza que deseja cancelar esse PIX?"
                            primaryButtonText="Sim"
                            secondaryButtonText="Não"
                          />
                        </Flex>
                      </Box>
                    </>
                  )}

                  {isOpenVisualizeReceivablesModal && (
                    <Modal
                      isOpen={isOpenVisualizeReceivablesModal}
                      onClose={onCloseVisualizeReceivablesModal}
                      isCentered
                      size="5xl"
                    >
                      <ModalOverlay />
                      <ModalContent>
                        <ModalHeader>Visualizar CARS </ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                          <PixGenerationForm
                            isReadonly
                            paymentChargeUuid={receivable.paymentChargeUuid}
                          />
                        </ModalBody>
                      </ModalContent>
                    </Modal>
                  )}
                  <PopoverCloseButton />
                </Flex>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </>
      )}
    </Popover>
  );
};

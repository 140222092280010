import { useToast } from "@chakra-ui/react";
import { useState } from "react";

import { DEFAULT_ERROR_MESSAGES } from "../../../../main/common/constants/defaultMessages";
import { IProcessingCarLow } from "../../../../types/main/processingCar";
import { confirmPayment } from "../../CARs/service/ProcessingCarService";

export const useAllProcessingCarTypeOfLowRequest = () => {
  const toast = useToast();

  const [isLoading, setIsLoading] = useState(false);

  const onSubmitCarLow = async ({
    data,
    onClose,
    refreshTable,
    reloadCar,
  }: {
    data: {
      body: Array<IProcessingCarLow>;
      isMassiveConfirmation: boolean;
    };
    onClose: () => void;
    refreshTable?: () => void;
    reloadCar?: () => void;
  }) => {
    try {
      setIsLoading(true);
      const response = await confirmPayment({
        isMassiveConfirmation: data.isMassiveConfirmation,
        data: data.body,
      });
      refreshTable?.();
      if (reloadCar) reloadCar();
      setTimeout(() => {
        onClose();
      }, 2000);
      toast({
        title: "Baixa efetuado.",
        description: response.body?.message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error: any) {
      if (reloadCar) reloadCar();
      toast({
        title: "Ocorreu um problema.",
        description:
          error?.body?.message ?? DEFAULT_ERROR_MESSAGES.MANAGE_ACTION,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return {
    isLoading,
    onSubmitCarLow,
  };
};

/* eslint-disable react/no-unstable-nested-components */
import { Button, Flex } from "@chakra-ui/react";
import { ColumnDef } from "@tanstack/react-table";
import { useMemo, useState } from "react";
import useSWR from "swr";
import { getDateFormatted } from "../../../../../../main/common/utils/dateHelper";
import { Backdrop } from "../../../../../../main/components/Backdrop";
import { TableServer } from "../../../../../../main/components/DataTableServer";
import { useTableServer } from "../../../../../../main/components/DataTableServer/useTableServer";
import {
  BackgroundProcessesLogsTypes,
  BackgroundProcessLogsTypeDescription,
  IBackgroundProcessesLogs,
} from "../../../../../../types/main/backgroundProcessesLogs";
import { Recommendation } from "../../../../BackgroundProcessesLogs/components/Recommendation";
import { getAllBackgroundLogs } from "../../../../BackgroundProcessesLogs/services/BackgroundProcessesLogs";
import { PureError } from "./Error";
import { PopoverBackgroundLogs } from "./PopoverLogs";

const handleTypeCell = (type: BackgroundProcessesLogsTypes) => {
  return BackgroundProcessLogsTypeDescription[type];
};

const typeOptions = [
  {
    value: "DUE_DATE",
    label: BackgroundProcessLogsTypeDescription.DUE_DATE,
  },
  {
    value: "PAYMENT_METHOD",
    label: BackgroundProcessLogsTypeDescription.PAYMENT_METHOD,
  },
  {
    value: "EMAIL",
    label: BackgroundProcessLogsTypeDescription.EMAIL,
  },
  {
    value: "PDF",
    label: BackgroundProcessLogsTypeDescription.PDF,
  },
  {
    value: "PROCESSING_REMITTANCE",
    label: BackgroundProcessLogsTypeDescription.PROCESSING_REMITTANCE,
  },
  {
    value: "NFE",
    label: BackgroundProcessLogsTypeDescription.NFE,
  },
  {
    value: "PRE_SAVED_INVOICE",
    label: BackgroundProcessLogsTypeDescription.PRE_SAVED_INVOICE,
  },
  {
    value: "PRE_SAVED_REMITTANCE",
    label: BackgroundProcessLogsTypeDescription.PRE_SAVED_REMITTANCE,
  },
  {
    value: "SENIOR_SYNC",
    label: BackgroundProcessLogsTypeDescription.SENIOR_SYNC,
  },
  {
    value: "ERROR_CREATE_TARIFF",
    label: BackgroundProcessLogsTypeDescription.ERROR_CREATE_TARIFF,
  },
  {
    value: "ERROR_CANCEL_TARIFF",
    label: BackgroundProcessLogsTypeDescription.ERROR_CANCEL_TARIFF,
  },
  {
    value: "NEXXERA_HANDLE_DOWNLOAD_FILES_DEBUG",
    label:
      BackgroundProcessLogsTypeDescription.NEXXERA_HANDLE_DOWNLOAD_FILES_DEBUG,
  },
  {
    value: "ERROR_CREATE_TARIFF",
    label: BackgroundProcessLogsTypeDescription.ERROR_CREATE_TARIFF,
  },
];

export const BackgroundLogs = () => {
  const [isExporting, setIsExporting] = useState(false);

  const {
    columnFiltersParams,
    columnSortingParams,
    paginationParams,
    columnFiltersState,
    paginationState,
    sortingState,
  } = useTableServer();

  const {
    data: logs,
    isLoading,
    mutate,
  } = useSWR(
    `/background-logs
    /${paginationParams.pageIndex}
    /${paginationParams.pageSize}
    /${columnSortingParams?.sortColumn}
    /${columnSortingParams?.sortOrder}}
    /${columnFiltersParams?.identifier}}
    /${columnFiltersParams?.type}}
    /${columnFiltersParams?.errorDescription}}`,
    () =>
      getAllBackgroundLogs({
        pagination: paginationParams,
        columnFilters: columnFiltersParams,
        sorting: columnSortingParams,
      }),
    {
      revalidateOnFocus: true,
      refreshInterval: 1000 * 15, // 15 seconds
    }
  );

  const columns = useMemo<ColumnDef<IBackgroundProcessesLogs, any>[]>(
    () => [
      {
        header: "Identificador",
        id: "identifier",
        accessorKey: "identifier",
        minSize: 50,
      },
      {
        header: "Tipo",
        id: "type",
        accessorKey: "type",
        filterFn: "select",
        meta: typeOptions,
        cell: (info) => handleTypeCell(info.getValue()),
      },
      {
        header: "Data Primeiro Ocorrência",
        id: "createdAt",
        accessorKey: "createdAt",
        cell: (info) => getDateFormatted(info.getValue(), "dd/MM/yyyy HH:mm"),
      },
      {
        header: "Última Ocorrência",
        id: "updatedAt",
        accessorKey: "updatedAt",
        cell: (info) => getDateFormatted(info.getValue(), "dd/MM/yyyy HH:mm"),
      },
      {
        header: "Problema encontrado",
        id: "errorDescription",
        accessorKey: "errorDescription",
      },

      {
        header: "Recomendação para verificar",
        id: "recommendation",
        cell: (info) => (
          <Recommendation
            type={info.row.original.type}
            extraTips={info.row.original.extraTips}
          />
        ),
      },
      {
        header: "Erro bruto?",
        id: "error",
        accessorKey: "error",
        enableSorting: false,
        enableColumnFilter: false,
        cell: (info) => <PureError error={info.getValue()} />,
      },
      {
        header: (info) => (
          <PopoverBackgroundLogs
            onLoading={setIsExporting}
            table={info.table}
          />
        ),
        id: "actions",
      },
    ],
    []
  );

  return (
    <>
      <Flex alignItems="center" mb="16px" justifyContent="flex-end">
        <Button isLoading={isLoading} ml="8px" onClick={() => mutate()}>
          Atualizar
        </Button>
      </Flex>
      <Flex justifyContent="flex-end" alignItems="center" mb="1rem" />
      <TableServer
        data={logs?.data ?? []}
        defaultColumns={columns}
        isLoading={isLoading}
        columnFiltersState={columnFiltersState}
        paginationState={{
          ...paginationState,
          pageCount: logs?.lastPage || 1,
        }}
        sortingState={sortingState}
      />
      {isExporting && <Backdrop />}
    </>
  );
};

import * as yup from "yup";

export const pixGenerationFormSchema = yup
  .object({
    document: yup
      .string()
      .optional()
      .nullable()
      .test("is-document-valid", "CPF/CNPJ inválido", (value) => {
        if (!value) return true;
        const valueWithoutMask = value.replace(/[^\d]+/g, "");
        return valueWithoutMask.length === 11 || valueWithoutMask.length === 14;
      }),
    invoiceId: yup.string().nullable().optional(),
  })
  .test(
    "at-least-one-field",
    "Deve ter pelo menos um campo preenchido",
    (form) => {
      return !!form.document || !!form.invoiceId;
    }
  );

export enum PaymentChargeStatusEnum {
  PAID = "PAID",
  EXPIRED = "EXPIRED",
  PENDING = "PENDING",
  REFUNDED = "REFUNDED",
  REFUND_REQUESTED = "REFUND_REQUESTED",
  REFUNDED_NOT_MADE = "REFUNDED_NOT_MADE",
}

export const paymentChargeStatus = {
  [PaymentChargeStatusEnum.PAID]: {
    description: "Pago",
    color: "green",
  },
  [PaymentChargeStatusEnum.EXPIRED]: {
    description: "Vencido",
    color: "red",
  },
  [PaymentChargeStatusEnum.PENDING]: {
    description: "Pendente",
    color: "yellow",
  },
  [PaymentChargeStatusEnum.REFUNDED]: {
    description: "Estornado",
    color: "gray",
  },
  [PaymentChargeStatusEnum.REFUND_REQUESTED]: {
    description: "Estorno Solicitado",
    color: "gray",
  },
  [PaymentChargeStatusEnum.REFUNDED_NOT_MADE]: {
    description: "Estorno Não Realizado",
    color: "red",
  },
};

import { AuthenticatedTemplate } from "@azure/msal-react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { UsersModule } from "../modules/Access/Users/Routes";
import { BackgroundProcessesLogsModule } from "../modules/Records/BackgroundProcessesLogs/Routes";
import { BillerModule } from "../modules/Records/Biller/Routes";
import { BoletoModule } from "../modules/Records/Boletos/Routes";
import { CARsModule } from "../modules/Records/CARs/Routes";
import { ChannelModule } from "../modules/Records/Channels/Routes";
import { ClearDataModule } from "../modules/Records/ClearData/Routes";
import { ClientsModule } from "../modules/Records/Clients/Routes";
import { ContractModule } from "../modules/Records/Contract/Routes";
import { InvoiceModule } from "../modules/Records/Invoice/Routes";
import { PaymentMethodModule } from "../modules/Records/PaymentMethod/Routes";
import { PaymentsChargesModules } from "../modules/Records/PaymentsChargesHistory/Routes";
import { ProductsModule } from "../modules/Records/Products/Routes";
import { RemittanceModule } from "../modules/Records/Remittance/Routes";
import { ReportsModule } from "../modules/Records/Reports/Routes";
import { ServiceProviderModule } from "../modules/Records/ServiceProvider/Routes";
import { ServicesProvisionModule } from "../modules/Records/ServiceProvision/Routes";
import { TariffModule } from "../modules/Records/Tariff/Routes";
import { TariffMovementModule } from "../modules/Records/TariffMovements/Routes";
import { TemplatesModule } from "../modules/Records/Templates/Routes";
import { WebHooksModule } from "../modules/Records/WebHooks/Routes";
import { LogsModule } from "../modules/Structure/Logs/Routes";
import { LoginProvider } from "./context/AuthContext";
import { RouteProvider } from "./context/RouteContext";
import { Home, Login } from "./pages";
import { Download } from "./pages/Download";
import { Refresh } from "./pages/Refresh";

export const App = () => {
  return (
    <BrowserRouter>
      <RouteProvider>
        <LoginProvider>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/refresh" element={<Refresh />} />
          </Routes>
          <AuthenticatedTemplate>
            <Routes>
              <Route path="/home" element={<Home />} />
              <Route path="/download/:path/:id" element={<Download />} />
            </Routes>
            <ClientsModule />
            <UsersModule />
            <TemplatesModule />
            <ProductsModule />
            <ServicesProvisionModule />
            <ServiceProviderModule />
            <BillerModule />
            <LogsModule />
            <ContractModule />
            <ChannelModule />
            <PaymentMethodModule />
            <TariffModule />
            <CARsModule />
            <InvoiceModule />
            <BoletoModule />
            <PaymentsChargesModules />
            <ReportsModule />
            <ClearDataModule />
            <TariffMovementModule />
            <RemittanceModule />
            <BackgroundProcessesLogsModule />
            <WebHooksModule />
          </AuthenticatedTemplate>
        </LoginProvider>
      </RouteProvider>
    </BrowserRouter>
  );
};

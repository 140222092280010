import { Route, Routes } from "react-router-dom";
import { PaymentChargeHistoricList } from "./pages/PaymentsChargesHistoryList";

export function PaymentsChargesModules() {
  return (
    <Routes>
      <Route path="/pagamentos" element={<PaymentChargeHistoricList />} />
    </Routes>
  );
}

import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { useCallback } from "react";
import { useFormContext } from "react-hook-form";

import { TSmallmd } from "../../../../../../main/components/Tipography";
import { IProcessingCarLow } from "../../../../../../types/main/processingCar";
import { useAllProcessingCarTypeOfLowRequest } from "../../../../Tariff/hooks/useAllProcessingCarTypeOfLowRequest";

interface IProps {
  arrayuuidTariff: string[];
  refreshTable?: () => void;
  reloadCar?: () => void;
  onClosePreceding: () => void;
  forcedManualLow: boolean;
}

const CarManuallyConfirm = ({
  arrayuuidTariff,
  refreshTable,
  reloadCar,
  onClosePreceding,
  forcedManualLow,
}: IProps) => {
  const { onSubmitCarLow } = useAllProcessingCarTypeOfLowRequest();
  const { watch } = useFormContext();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const watchReasonForManualLow = watch("reasonForManualLow");
  const watchProcessingCarTypeOfLowUuid = watch("processingCarTypeOfLowUuid");
  const watchDateOfLow = watch("dateOfLow");

  const handleClose = useCallback(() => {
    onClose();
    onClosePreceding();
  }, [onClose, onClosePreceding]);

  const handleSubmit = useCallback(() => {
    const prepareToSubmit: Array<IProcessingCarLow> = arrayuuidTariff.map(
      (item: string) => {
        return {
          uuidCar: item,
          processingCarTypeOfLowUuid: watchProcessingCarTypeOfLowUuid,
          dateOfLow: watchDateOfLow,
          reasonForManualLow: watchReasonForManualLow,
        };
      }
    );
    if (prepareToSubmit && prepareToSubmit.length > 0) {
      onSubmitCarLow({
        data: {
          body: prepareToSubmit,
          isMassiveConfirmation: false,
        },
        onClose,
        refreshTable,
        reloadCar,
      });
    }
    handleClose();
  }, [
    arrayuuidTariff,
    handleClose,
    onClose,
    onSubmitCarLow,
    refreshTable,
    reloadCar,
    watchDateOfLow,
    watchProcessingCarTypeOfLowUuid,
    watchReasonForManualLow,
  ]);

  return (
    <>
      <Button
        isDisabled={
          !(
            watchReasonForManualLow &&
            watchReasonForManualLow.length > 0 &&
            watchDateOfLow
          )
        }
        onClick={onOpen}
      >
        Confirmar
      </Button>

      <Modal
        closeOnOverlayClick={!forcedManualLow}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        size="lg"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Tem certeza que quer dar baixa ma tarifa?</ModalHeader>
          {!forcedManualLow && <ModalCloseButton onClick={handleClose} />}
          <ModalBody>
            <TSmallmd>Essa ação não pode ser desfeita posteriormente</TSmallmd>
          </ModalBody>
          <ModalFooter>
            {!forcedManualLow && (
              <Button colorScheme="gray" mr="12px" onClick={handleClose}>
                Cancelar ação
              </Button>
            )}
            <Button colorScheme="red" onClick={handleSubmit}>
              Dar baixa na CAR
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
export { CarManuallyConfirm };

import React from "react";

export const ToastError = ({ message }: { message?: string }) => (
  <div>
    Por favor, tente novamente mais tarde. Se o erro persistir, entre em contato
    com o suporte.
    <br />
    {message && (
      <span>
        <strong>Mensagem:</strong> {message.replace("Error: ", "")}
      </span>
    )}
  </div>
);

export const CREATE_TARIFF_PERMISSION_LEVEL = ["Master", "Admin", "Auditor"];

type ITariffMap = {
  [key: string]: boolean;
};

export const CAN_PERMISSION_SET_MANUAL_LOW: ITariffMap = {
  Master: true,
  Admin: true,
  Auditor: true,
  Operador: true,
} as const;

export const CAN_PERMISSION_GENERATE_PAYMENT_PIX: ITariffMap = {
  Master: true,
  Admin: true,
  Auditor: false,
  Operador: true,
} as const;

export const CAN_PERMISSION_CANCEL: ITariffMap = {
  Master: true,
  Admin: true,
  Auditor: true,
  Operador: false,
} as const;

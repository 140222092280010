import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Flex,
  FormControl,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { EnumRadioGroup } from "../../../../../main/components/CustomRadioGroup/EnumRadioGroup";
import { DatePicker } from "../../../../../main/components/DateTimePicker/DatePicker";
import { TLargemd } from "../../../../../main/components/Tipography";
import { exportTariffs } from "../../service/TariffService";
import { exportTariffsFormSchema } from "../../validation/exportTariffsFormSchema";

type Props = {
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
  filters: any;
};

export const ExportTariffsPDFsForm = ({
  onCancel,
  filters,
  onSuccess,
}: Props) => {
  const toast = useToast();

  const [isLoading, setIsLoading] = useState(false);

  const methods = useForm({
    defaultValues: {
      field: "endDate",
      date: new Date(),
    },
    resolver: yupResolver(exportTariffsFormSchema),
  });

  const {
    formState: { errors },
  } = methods;

  async function onSubmit({ date, ...rest }: any) {
    try {
      setIsLoading(true);
      toast({
        title: "Aguarde",
        description: "A exportação dos PDFs está sendo agendada.",
        status: "info",
        duration: 5000,
        isClosable: true,
      });
      const newDate = date;
      date.setHours(15);

      await exportTariffs({
        search: {
          ...filters,
          ...rest,
          date: newDate,
          blobName: undefined,
        },
      });

      onSuccess();

      toast({
        title: "Sucesso",
        description: "A exportação dos PDFs foi agendada com sucesso.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error: Error | any) {
      const message = error.response?.data?.message;
      toast({
        title: "Erro ao agendar exportação",
        description: (
          <div>
            Ocorreu um erro ao tentar agendar a exportação. Por favor, tente
            novamente mais tarde. Se o problema persistir, entre em contato com
            o suporte.
            {message && (
              <div>
                <br />
                <strong>Detalhes do erro:</strong> {message}
              </div>
            )}
          </div>
        ),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Alert status="info" mb={4} borderRadius={4}>
          <AlertIcon />

          <AlertDescription ml={10}>
            <ul>
              <li>
                Para exportar os PDFs das tarifas, é necessário que pelo menos
                um filtro esteja selecionado.
              </li>
              <li>
                Apenas as tarifas que possuem PDFs disponíveis serão exportadas.
              </li>
              <li>
                Caso nenhuma tarifa seja encontrada, a exportação não será
                realizada.
              </li>

              <li>
                Esse processo pode levar alguns minutos. Um e-mail será enviado
                ao operador que solicitou a exportação com um link para um
                arquivo ZIP contendo os PDFs das tarifas em anexo.
              </li>
              <li>
                A exportação pode demorar de 3 até 15 minutos a depender dos
                filtros aplicados
              </li>
              <li>Buscas por Referência considerarão o mês inteiro.</li>
              <li>
                Buscas por Data de Vencimento considerarão apenas o dia
                selecionado.
              </li>
            </ul>
          </AlertDescription>
        </Alert>

        <Flex justifyContent="space-between" alignContent="center">
          <Flex direction="column">
            <TLargemd whiteSpace="nowrap" color="gray.700" mb="8px">
              Buscar por
            </TLargemd>
            <EnumRadioGroup
              defaultCustomValue="endDate"
              fieldname="field"
              options={[
                {
                  label: "Por Referência",
                  value: "endDate",
                },
                {
                  label: "Por Data de Vencimento",
                  value: "dueDate",
                },
              ]}
            />
          </Flex>

          <Flex direction="column">
            <Flex flexDirection="column">
              <FormControl isInvalid={!!errors.date}>
                <TLargemd whiteSpace="nowrap" color="gray.700" mb="8px">
                  Data
                </TLargemd>
                <DatePicker fieldname="date" />
              </FormControl>
            </Flex>
          </Flex>
        </Flex>

        <Flex gap={3} mt={5}>
          <Button variant="ghost" onClick={onCancel}>
            Cancelar
          </Button>

          <Button
            variant="solid"
            type="submit"
            isLoading={isLoading}
            disabled={Object.values(filters).filter(Boolean).length === 0}
          >
            Agendar Exportação
          </Button>
        </Flex>
      </form>
    </FormProvider>
  );
};

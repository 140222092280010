import { IBiller } from "./biller";
import { IContracts } from "./contract";
import { IFieldDefaultWithId } from "./fieldDefault";
import { IInvoice } from "./invoice";
import {
  ITariffCar,
  ITariffCarData,
  ITariffCarWithCalculate,
} from "./tariffCar";
import { ITariffUsageEvents } from "./tariffUsageEvent";

interface ITariffFields {
  id: number;
  contractUuid: string;
  contract: IContracts;
  billerUuid: string;
  biller: IBiller;
  billerInternalUuid?: string | null;
  invoiceId?: number;
  invoice?: IInvoice;
  tariffIdentifier?: string;
  startDate: Date;
  endDate: Date;
  projectDescription?: string;
  idProject?: string;
  tariffCar: ITariffCar[];
  dueDate?: Date | null;
  usageEvents?: ITariffUsageEvents | null;
  usernameLastInteraction: string;
  blobName?: string | null;
  encryptedBlobName?: string | null;
  status: "OPEN" | "CLOSED" | "CANCELED";
  statusRelevantDate?: Date;
}

type ITariffCarCreateAndUdate = ITariffCarData;

// prettier-ignore
interface ITariffData extends Omit<ITariffFields, "id" | "contract" | "product" | "biller" | "invoice" | "tariffCar" | "contractUuid" | "usernameLastInteraction" |  "status" | "statusRelevantDate"> {
  id?: number;
  idContract?: number;
  contractUuid?: string;
  tariffCarData: Array<ITariffCarCreateAndUdate>;
}

type ITariffDataUpdate = Partial<ITariffData>;

type ITotalTariff = {
  totalInterestReceived: number;
  totalInterestProvided: number;
  totalFineReceived: number;
  totalFineProvided: number;
  totalReceived: number;
  totalProvided: number;
  valueReceived: number;
  valueProvided: number;
  discountReceived: number;
  discountProvided: number;
};

type ITariff = IFieldDefaultWithId &
  ITariffFields & {
    id: number;
  };

interface ITariffWithPaymentMethod extends Omit<ITariff, "tariffCar"> {
  tariffCar: ITariffCarWithCalculate[];
  totalTariff: ITotalTariff;
  tariffStatus: {
    status: "OPEN" | "CLOSED" | "CANCELED";
    date?: Date;
  };
}

interface ITariffWithInvoice extends ITariffWithPaymentMethod {
  tariffsInInvoice: ITariffWithPaymentMethod[];
  nfeGenerated: boolean;
}

export const tariffStatusLabel = {
  OPEN: { description: "Em aberto", color: "yellow" },
  CLOSED: { description: "Concluída", color: "green" },
  CANCELED: { description: "Cancelada", color: "red" },
};

export type {
  ITariff,
  ITariffFields,
  ITariffData,
  ITariffWithPaymentMethod,
  ITariffDataUpdate,
  ITotalTariff,
  ITariffWithInvoice,
};

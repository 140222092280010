import { PaginationState } from "@tanstack/react-table";
import { AxiosResponse } from "axios";
import {
  TableFilteredParams,
  TableSortingParams,
} from "../../../../main/components/DataTableServer/useTableServer";
import { recordApi } from "../../../../main/services/RecordService";
import { IBoletoFilters } from "../../../../types/main/boleto";
import { IPaginateResponse } from "../../../../types/main/pagination";
import { IResponse } from "../../../../types/main/response";
import { IPaymentsChargesListResponse } from "../types/paymentsChargesListResponse.interface";

const MODULE_PATH = "/payments-charges";

type ListParams = {
  pagination?: PaginationState;
  columnFilters?: TableFilteredParams;
  sorting?: TableSortingParams;
  filters?: IBoletoFilters;
};
export const paymentsChargesService = {
  async getAll({ columnFilters, pagination, sorting }: ListParams) {
    try {
      const page = pagination?.pageIndex;

      const {
        data,
      }: AxiosResponse<
        IResponse<IPaginateResponse<IPaymentsChargesListResponse>>
      > = await recordApi.get(`${MODULE_PATH}/`, {
        params: {
          page: page === undefined ? 1 : page + 1,
          take: pagination?.pageSize ?? 10,
          ...columnFilters,
          ...sorting,
        },
      });
      return data;
    } catch (err: any) {
      throw err?.response?.data;
    }
  },

  async expire(input: { paymentChargeUuid: string }) {
    try {
      await recordApi.patch(`${MODULE_PATH}/expire/${input.paymentChargeUuid}`);
    } catch (err: any) {
      throw err?.response?.data;
    }
  },
};

/* eslint-disable react/no-unstable-nested-components */
import { ArrowForwardIcon, InfoIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  GridItem,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { ColumnDef, PaginationState } from "@tanstack/react-table";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { getDateFormatted } from "../../../../main/common/utils/dateHelper";
import { getSeniorSyncMessage } from "../../../../main/common/utils/getSeniorSyncMessage";
import { Backdrop } from "../../../../main/components/Backdrop";
import {
  CustomBreadcrumb,
  IBreadcrumbProps,
} from "../../../../main/components/CustomBreadcrumb";
import { TableServer } from "../../../../main/components/DataTableServer";
import {
  TableFilteredParams,
  TableSortingParams,
  useTableServer,
} from "../../../../main/components/DataTableServer/useTableServer";
import { HLarge2xl } from "../../../../main/components/Tipography";
import { Wrapper } from "../../../../main/components/Wrapper";
import { CARList } from "../../../../types/main/tariffCar";
import { DEFAULT_VALIDATION_IN_REMITTANCE } from "../../Tariff/constants/tariff.constants";
import { CARsDueDateModal } from "../components/CARsDueDateModal";
import { PixGenerationForm } from "../components/PixGenerationForm";
import { MassManualWriteOff } from "../components/mass-manual-write-off";
import { PopoverCARList } from "../components/PopoverList";
import { ReceivableStatusOptions } from "../interfaces/status";
import { getAllCARs } from "../service/CARsService";

const breadcrumb: IBreadcrumbProps[] = [
  { description: "Gerenciamento de Contas a Receber", href: "" },
];

export function CARsList() {
  const [CARs, setCARs] = useState<{
    data: CARList[];
    total: number;
  }>({
    data: [],
    total: 0,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const {
    isOpen: isOpenMassManualWriteOff,
    onOpen: onOpenMassManualWriteOff,
    onClose: onCloseMassManualWriteOff,
  } = useDisclosure();

  const {
    isOpen: isOpenPixGeneration,
    onOpen: onOpenPixGeneration,
    onClose: onClosePixGeneration,
  } = useDisclosure();

  const handleTariffList = async (
    pagination?: PaginationState,
    filters?: TableFilteredParams,
    sorting?: TableSortingParams
  ) => {
    setIsLoading(true);
    const response = await getAllCARs({
      filters,
      pagination,
      sorting,
    });

    if (response.type === "success" && response.body) {
      setCARs({
        data: response.body.data,
        total: response.body.lastPage,
      });
    }
    setIsLoading(false);
  };

  const {
    columnFiltersParams,
    columnSortingParams,
    paginationParams,
    columnFiltersState,
    paginationState,
    sortingState,
  } = useTableServer({
    pageCount: CARs.total,
  });

  const columns = useMemo<ColumnDef<CARList, any>[]>(
    () => [
      {
        header: "ID",
        accessorKey: "id",
      },
      {
        header: "DATA DE CRIAÇÃO",
        accessorKey: "createdAt",
        filterFn: "date",
        minSize: 150,
        cell: (info) => {
          return getDateFormatted(info.getValue());
        },
      },
      {
        header: "TARIFA",
        id: "tariffId",
        accessorKey: "tariffId",
        filterFn: "fuzzy",
        cell: (info) => {
          return (
            <HStack>
              <span>{info.getValue()}</span>
              <Flex
                w="24px"
                h="24px"
                as={Link}
                to={`/managetariff/${info.getValue()}`}
                borderRadius="6px"
                backgroundColor="gray.200"
                justify="center"
                align="center"
                transition="0.2s"
                _hover={{
                  backgroundColor: "blue.700",
                  color: "white",
                  cursor: "pointer",
                }}
              >
                <ArrowForwardIcon />
              </Flex>
            </HStack>
          );
        },
      },
      {
        header: "DATA DE VENCIMENTO",
        id: "dueDate",
        accessorKey: "dueDate",
        filterFn: "date",
        enableSorting: false,
        cell: (info) => {
          const formattedDueDate = getDateFormatted(info.getValue());

          const isInRemittance =
            !!info.row.original.chargeUuid &&
            DEFAULT_VALIDATION_IN_REMITTANCE.includes(
              info.row.original.statusEnum
            );

          return (
            <CARsDueDateModal
              isInRemittance={isInRemittance}
              fieldName="dueDate"
              date={formattedDueDate}
              id={info.row.original.tariffId}
              refreshTable={handleTariffList}
            />
          );
        },
      },
      {
        header: "VALOR",
        id: "carValue",
        accessorKey: "carValue",
        filterFn: "fuzzy",
        cell: (info) => {
          return Number(info.getValue() || 0).toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          });
        },
      },
      {
        header: "JUROS",
        accessorKey: "invoiceInterest",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (info) => {
          return Number(info.getValue() || 0).toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          });
        },
      },
      {
        header: "MULTA",
        accessorKey: "invoiceFine",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (info) => {
          return Number(info.getValue() || 0).toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          });
        },
      },
      {
        header: "DESCONTO",
        accessorKey: "remittanceDiscount",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (info) => {
          return Number(info.getValue() || 0).toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          });
        },
      },
      {
        header: "VALOR TOTAL",
        accessorKey: "invoiceTotal",
        enableColumnFilter: false,
        enableSorting: false,
        filterFn: "fuzzy",
        cell: (info) => {
          return Number(info.getValue() || 0).toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          });
        },
      },
      {
        header: "CLIENTE",
        accessorKey: "clientName",
        filterFn: "fuzzy",
      },
      {
        header: "DOC. CLIENTE",
        accessorKey: "clientDocument",
        filterFn: "fuzzy",
      },
      {
        header: "Identificador",
        accessorKey: "tariffIdentifier",
        enableSorting: false,
        enableColumnFilter: true,
      },
      {
        header: "CONTRATO",
        accessorKey: "contract",
        filterFn: "fuzzy",
      },
      {
        header: "ID CANAL MÃE",
        accessorKey: "channelParentId",
        filterFn: "fuzzy",
      },
      {
        header: "CANAL MÃE",
        accessorKey: "parentChannel",
        filterFn: "fuzzy",
      },
      {
        header: "CANAL",
        accessorKey: "channel",
        filterFn: "fuzzy",
      },
      {
        header: "PRODUTO",
        accessorKey: "product",
        filterFn: "selectProduct",
        minSize: 200,
        // meta: productsOptions,
      },
      {
        header: "SERVIÇO",
        accessorKey: "service",
        filterFn: "selectService",
        minSize: 200,
      },
      {
        header: "Identificador",
        accessorKey: "tariffIdentifier",
        enableSorting: false,
        enableColumnFilter: true,
      },
      {
        header: "STATUS",
        accessorKey: "status",
        filterFn: "select",
        meta: ReceivableStatusOptions,
        minSize: 200,
        cell: (info) => {
          const splitted = info.row.original.manualLowReason
            ? info.getValue().split("(")[0]
            : info.getValue();

          return info.row.original.manualLowReason ? (
            <HStack>
              <span>{splitted}</span>
              <Tooltip
                label={info.row.original.manualLowReason}
                fontSize="sm"
                marginLeft="8px"
              >
                <InfoIcon />
              </Tooltip>
            </HStack>
          ) : (
            <span>{info.getValue()}</span>
          );
        },
      },
      {
        header: "Data da Baixa",
        accessorKey: "dateOfLow",
        filterFn: "date",

        cell: (info) => {
          if (!info.getValue()) return "";

          return format(new Date(info.getValue()), "dd/MM/yyyy", {
            locale: ptBR,
          });
        },
      },
      {
        header: "Remessa vinculada",
        accessorKey: "remittance",
        cell: (info) => {
          const { chargeId } = info.row.original;

          return (
            <Tooltip label={`Cobrança: ${chargeId}`} hasArrow arrowSize={15}>
              <span>{info.getValue()}</span>
            </Tooltip>
          );
        },
      },
      {
        header: "Nº Boleto",
        accessorKey: "boletoOurNumber",
        enableSorting: false,
      },
      {
        header: "Boleto Gerado?",
        accessorKey: "boletoEmitted",
        enableSorting: false,
        enableColumnFilter: false,
        cell: (info) => {
          return info.getValue() ? "Sim" : "Não";
        },
      },
      {
        header: "Boleto Enviado?",
        accessorKey: "boletoSent",
        enableSorting: false,
        cell: (info) => {
          return info.getValue() ? "Sim" : "Não";
        },
      },
      {
        header: "Rejeição do Banco",
        accessorKey: "rejectionMotive",
      },
      {
        header: "USUÁRIO/TARIFADOR",
        accessorKey: "biller",
      },
      {
        header: "Sincronização Senior",
        accessorKey: "lastSeniorSync",
        cell: (info) => {
          const { cellValue, message } = getSeniorSyncMessage(
            info,
            info.row.original.updatedAt
          );

          return (
            <Flex alignItems="center" gap={4}>
              <Tooltip label={message} fontSize="sm" marginLeft="8px">
                <InfoIcon color="blue.600" />
              </Tooltip>
              {cellValue}
            </Flex>
          );
        },
      },
      {
        header: "Sincronização de mudança de status Senior",
        accessorKey: "lastSeniorStatusSync",
        cell: (info) => {
          const { cellValue, message } = getSeniorSyncMessage(
            info,
            info.row.original.processingCarUpdatedAt
          );

          return (
            <Flex alignItems="center" gap={4}>
              <Tooltip label={message} fontSize="sm" marginLeft="8px">
                <InfoIcon color="blue.600" />
              </Tooltip>
              {cellValue}
            </Flex>
          );
        },
      },
      {
        header: (info) => (
          <PopoverCARList
            refetch={handleTariffList}
            table={info.table}
            onLoading={setIsExporting}
          />
        ),
        id: "actions",
        cell: (info) => (
          <PopoverCARList
            info={info}
            refetch={handleTariffList}
            table={info.table}
            onLoading={setIsExporting}
          />
        ),
      },
    ],
    []
  );

  useEffect(() => {
    handleTariffList(
      paginationParams,
      columnFiltersParams,
      columnSortingParams
    );
  }, [columnFiltersParams, paginationParams, columnSortingParams]);

  return (
    <Wrapper>
      <GridItem rowSpan={1} colSpan={4}>
        <CustomBreadcrumb breadcrumb={breadcrumb} />
        <Flex justifyContent="space-between" alignItems="center">
          <HLarge2xl mb="32px" color="gray.600">
            Gerenciamento de Contas a Receber
          </HLarge2xl>

          <div>
            <Button
              variant="outline"
              marginRight="16px"
              onClick={onOpenMassManualWriteOff}
            >
              Baixa em massa
            </Button>

            <Button
              variant="outline"
              marginRight="16px"
              onClick={onOpenPixGeneration}
            >
              Gerar PIX
            </Button>
          </div>
        </Flex>
        <TableServer
          refresh={handleTariffList}
          data={CARs.data}
          defaultColumns={columns}
          isLoading={isLoading}
          columnFiltersState={columnFiltersState}
          paginationState={paginationState}
          sortingState={sortingState}
        />
      </GridItem>
      {isOpenMassManualWriteOff && (
        <MassManualWriteOff
          isOpen={isOpenMassManualWriteOff}
          onClose={onCloseMassManualWriteOff}
        />
      )}

      {isOpenPixGeneration && (
        <Modal
          isOpen={isOpenPixGeneration}
          onClose={onClosePixGeneration}
          isCentered
          size="5xl"
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Gerar PIX para pagamentos</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <PixGenerationForm />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}

      {isExporting && <Backdrop />}
    </Wrapper>
  );
}

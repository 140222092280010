/* eslint-disable react/no-unstable-nested-components */
import { CopyIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { ColumnDef } from "@tanstack/react-table";
import React, { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { getDateFormatted } from "../../../../main/common/utils/dateHelper";
import { Backdrop } from "../../../../main/components/Backdrop";
import { ConfirmationPopover } from "../../../../main/components/ConfirmationPopover";
import { BooleanRadioGroup } from "../../../../main/components/CustomRadioGroup/BooleanRadioGroup";
import { TableServer } from "../../../../main/components/DataTableServer";
import { useTableServer } from "../../../../main/components/DataTableServer/useTableServer";
import { NumberFilterInput } from "../../../../main/components/NumberFilterInput";
import { ToastError } from "../../../../main/components/ToastError/ToastError";
import { useAuth } from "../../../../main/hooks/useAuth";
import { CAN_PERMISSION_GENERATE_PAYMENT_PIX } from "../../Tariff/constants/permissions";
import { pixGenerationFormSchema } from "../schemas/pix-generation-form-schema";
import {
  generatePix,
  getCarsAvailableToGeneratePix,
} from "../service/CARsService";

type ReceivablePixGeneration = {
  id: number;
  uuid: string;
  dueDate: Date;
  invoiceInterest: number;
  invoiceFine: number;
  remittanceDiscount: number;
  invoiceTotal: number;
  tariff: {
    tariffIdentifier: string;
  };
};

export const PixGenerationForm = (
  {
    isReadonly,
    paymentChargeUuid,
  }: { isReadonly?: boolean; paymentChargeUuid?: string } = {
    isReadonly: false,
  }
) => {
  const { permissionLevel } = useAuth();
  const { paginationState } = useTableServer({
    pageSize: 1_000,
  });
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const tableRef = useRef<any>(null);

  const [pix, setPix] = useState<{
    total: number;
    transactionCode: string;
  } | null>();

  const [CARs, setCARs] = useState<{
    data: ReceivablePixGeneration[];
  }>({
    data: [],
  });

  const methods = useForm({
    defaultValues: {
      document: "",
      discardFineAndInterest: false,
      invoiceId: "",
    },
    resolver: yupResolver(pixGenerationFormSchema),
  });

  const onSubmit = async (values: any) => {
    tableRef.current.unselectAllRows();
    setIsLoading(true);
    setPix(null);
    try {
      const response = await getCarsAvailableToGeneratePix({
        document: values.document?.replace(/\D/g, ""),
        invoiceId: values.invoiceId,
        paymentChargeUuid,
      });

      setCARs({
        data: response,
      });

      setIsLoading(false);
    } catch (error: Error | any) {
      const errorMessage =
        error.message || "Ocorreu um erro ao buscar os CARs.";
      toast({
        title: "Ocorreu um erro.",
        description: <ToastError message={errorMessage} />,

        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (!paymentChargeUuid) return;
    onSubmit({ document: "", invoiceId: "" });
  }, [paymentChargeUuid]);

  if (!CAN_PERMISSION_GENERATE_PAYMENT_PIX[permissionLevel]) {
    return null;
  }
  const columns: ColumnDef<ReceivablePixGeneration, any>[] = [
    {
      accessorKey: "id",
      enableSorting: false,
      header: ({ table }) => (
        <Flex gap={2}>
          <Checkbox
            onChange={() => table.toggleAllRowsSelected()}
            isChecked={table.getIsAllRowsSelected()}
          />
          <div>Id</div>
        </Flex>
      ),

      cell: ({ row, getValue }) => (
        <div>
          <div>
            <Checkbox
              {...{
                isChecked: row.getIsSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />{" "}
            {getValue<boolean>()}
          </div>
        </div>
      ),
    },

    {
      header: "Identificador da tarifa",
      cell: ({ row }) => {
        return <div>{row.original.tariff.tariffIdentifier}</div>;
      },
    },
    {
      header: "DATA DE VENCIMENTO",
      accessorKey: "dueDate",
      filterFn: "date",
      enableSorting: false,
      enableColumnFilter: false,
      minSize: 150,
      cell: (info) => {
        return getDateFormatted(info.getValue());
      },
    },

    {
      header: "VALOR",
      id: "carValue",
      accessorKey: "carValue",
      filterFn: "fuzzy",
      enableSorting: false,
      enableColumnFilter: false,
      cell: (info) => {
        return Number(info.getValue() || 0).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });
      },
    },
    {
      header: "JUROS",
      accessorKey: "invoiceInterest",
      enableColumnFilter: false,
      enableSorting: false,
      cell: (info) => {
        return Number(info.getValue() || 0).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });
      },
    },
    {
      header: "MULTA",
      accessorKey: "invoiceFine",
      enableColumnFilter: false,
      enableSorting: false,
      cell: (info) => {
        return Number(info.getValue() || 0).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });
      },
    },
    {
      header: "DESCONTO",
      accessorKey: "remittanceDiscount",
      enableColumnFilter: false,
      enableSorting: false,
      cell: (info) => {
        return Number(info.getValue() || 0).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });
      },
    },
    {
      header: "VALOR TOTAL",
      accessorKey: "invoiceTotal",
      enableSorting: false,
      enableColumnFilter: false,
      filterFn: "fuzzy",
      cell: (info) => {
        return Number(info.getValue() || 0).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });
      },
    },
  ];

  const onConfirmation = async () => {
    setIsLoading(true);

    try {
      const receivables = tableRef.current
        .getSelectedItems()
        .map((item: ReceivablePixGeneration) => ({
          id: item.id,
        }));
      const formValue = methods.getValues();
      const pixResponse = await generatePix({
        receivables,
        discardInterestAndFine: formValue.discardFineAndInterest,
        document: formValue.document,
      });
      setPix(pixResponse);
      const receivablesToMaintain = CARs.data.filter(
        (car) =>
          !receivables.find((r: ReceivablePixGeneration) => r.id === car.id)
      );

      tableRef.current.unselectAllRows();
      setCARs({
        data: receivablesToMaintain,
      });
      toast({
        title: "Sucesso",
        description: "O PIX foi gerado com sucesso.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error: Error | any) {
      const errorMessage = error.message || "Ocorreu um erro ao gerar o PIX.";
      toast({
        title: "Ocorreu um erro.",
        description: <ToastError message={errorMessage} />,

        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      {!isReadonly && (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Flex alignItems="baseline" justifyContent="space-between">
              <FormControl maxWidth="300px">
                <FormLabel htmlFor="document">Documento</FormLabel>

                <Input
                  id="document"
                  placeholder="CPF/CNPJ"
                  {...methods.register("document", {
                    required: false,
                  })}
                  autoComplete="off"
                />
              </FormControl>

              <FormControl maxWidth="300px">
                <FormLabel htmlFor="invoiceId">Id da fatura</FormLabel>
                <NumberFilterInput
                  fieldname="invoiceId"
                  type="number"
                  maxLength={10}
                />
              </FormControl>
              <Flex flexDirection="column">
                <FormLabel htmlFor="discardFineAndInterest">
                  Ignorar multa e juros?
                </FormLabel>
                <BooleanRadioGroup
                  defaultCustomValue={false}
                  fieldname="discardFineAndInterest"
                  options={[
                    { label: "Sim", value: true },
                    { label: "Não", value: false },
                  ]}
                />
              </Flex>
            </Flex>

            <Flex my={4}>
              <Button variant="solid" type="submit">
                Buscar CARs
              </Button>
            </Flex>
          </form>
        </FormProvider>
      )}

      {isLoading && <Backdrop />}

      <Flex maxH="400px" overflowY="scroll" flexDirection="column" marginY={4}>
        <TableServer
          ref={tableRef}
          data={CARs.data}
          defaultColumns={columns}
          isLoading={isLoading}
          hidePerPageControl
          hideAllFilters
          hidePagination
          hideColumnVisibilityControl
          hidePageSkipControl
          paginationState={paginationState}
        />
      </Flex>

      <Divider my={4} />

      {!isReadonly && (
        <Flex justifyContent="flex-end">
          <ConfirmationPopover
            disabled={isLoading}
            triggerButtonText="Gerar PIX"
            onPrimaryButtonClick={() => {
              onConfirmation();
            }}
            title="Confirmação"
            text="Tem certeza que deseja gerar um PIX de cobrança com os CARs selecionados?"
            primaryButtonText="Sim"
            secondaryButtonText="Não"
          />
        </Flex>
      )}

      <Divider my={4} />

      {pix && (
        <Alert
          status="success"
          mb="32px"
          borderRadius={4}
          display="flex"
          flexDirection="column"
        >
          <AlertDescription display="flex" flexDirection="column" gap={2}>
            <AlertTitle fontWeight={500} display="flex">
              <AlertIcon />
              <div>PIX gerado com sucesso!</div>
            </AlertTitle>
            <div>
              <strong>Valor total:</strong>{" "}
              {pix.total.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })}
            </div>
            <strong>
              PIX Copia e Cola{" "}
              <CopyIcon
                cursor="pointer"
                onClick={() => {
                  navigator.clipboard.writeText(pix.transactionCode);
                  toast({
                    title: "Código copiado",
                    description:
                      "O código do PIX foi copiado para a área de transferência.",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                  });
                }}
              />
              :
            </strong>{" "}
            {pix.transactionCode}
          </AlertDescription>
        </Alert>
      )}
    </div>
  );
};

export const PixTable = ({
  data,
  columns,
  isLoading,
  paginationState,
}: any) => {
  return (
    <TableServer
      data={data}
      defaultColumns={columns}
      isLoading={isLoading}
      hidePerPageControl
      hideAllFilters
      hidePagination
      hideColumnVisibilityControl
      hidePageSkipControl
      paginationState={paginationState}
    />
  );
};
